
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappsForm from "../../components/webapps/form"

const ENTITYID="nnseminarseries"


const onsbranchpickerfields = [
	{"label":"Name", "dbfield": "onsbranch_name", "type": "text", "filtertype": "textbox"},
	{"label":"URL", "dbfield": "onsbranch_url", "type": "text", "filtertype": "textbox"}
];

const onsbranchpickerfilter = [
	//{"field":"onsorganization_id", "operation": ">", "value": 0},
];


const searchFields = [
	{"label":"Name", "dbfield": "nnseminarseries_name", "type": "text", "filtertype": "text"},
	{"label":"Organizer", "dbfield": "onsbranch_name", "type": "text", "filtertype": "text"}
];

const newFormFields = [
	[
		{"label":"Name", "field": "nnseminarseries_name", "value": "", "input": "text", "mode": "required"},
		{"label":"Organizer", "field": "onsbranch_name", "value": "", "input": "picker", "mode": "required", "pickerfields": onsbranchpickerfields, "pickerfilters": onsbranchpickerfilter},
	]
];

const formFields = [
	[
		{"label":"Name", "field": "nnseminarseries_name", "value": "", "input": "text", "mode": "readonly"},
		{"label":"Organizer", "field": "onsbranch_name", "value": "", "input": "picker", "mode": "readonly"},
	]
];


const detailsFormFields = [
	//
	{"label":"Summary", "field": "nnseminarseries_summary", "value": "", "input": "textarea", "mode": "required"},
	{"label":"Description", "field": "nnseminarseries_description", "value": "", "input": "richtextarea", "mode": "required"},
];


const subformFields = [
	{
		"subformid": "nnseminarseriesnotification",
		"label": "Notification List",
		"table": "nnseminarseriesnotification",
		"sort": "nnseminarseriesnotification_value",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Email Address",
				"field": "nnseminarseriesnotification_value",
				"subtotalid": "",
				"value": "",
				"input": "email",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "nnseminarseriesnotification_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "nnseminarseriesnotification_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	}
];

const SeminarseriesPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout fullPath={location.pathname}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={true}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["nnseminarseries_name"]}
				searchFields={searchFields}
				editFields={[formFields[0].concat(detailsFormFields)]}
				newformFields={[newFormFields[0].concat(detailsFormFields)]}
				editSubFormFields={subformFields}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default SeminarseriesPage;
